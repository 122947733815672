import React, { Component } from 'react';
import { useStaticQuery, graphql, StaticQuery} from "gatsby";
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import PortfoliosList from './PortfoliosList';
import { css } from "@emotion/core";
import Select from 'react-select';
import ReactDOM from 'react-dom';

const options = [];
const whatever = [];
const allOptions = [];
const formArray = {
  categories: []
};

function revealArchived() {
  var pList = document.getElementById("portfolioListContainer");
  var pHeader = document.getElementById("portfolioHeader");
  if (pList.classList.contains('activeP')) {
    pList.classList.remove('activeP');
    pList.classList.add('archivedP');
    pHeader.classList.remove('pActive');
    pHeader.classList.add('pArchived');
  } else {
    pList.classList.add('activeP');
    pList.classList.remove('archivedP');
    pHeader.classList.add('pActive');
    pHeader.classList.remove('pArchived');
  }
}


function portfolioFormOpen() {
  var pForm = document.getElementById("portfolioForm");
  pForm.classList.add('active');
}

function portfolioFormClose() {
  var pForm = document.getElementById("portfolioForm");
  pForm.classList.remove('active');
  //document.getElementById('pForm').reset();
}
function toggleMatching(e) {
  const matchedID = document.getElementsByClassName('select'+e.currentTarget.id);
  
  for (var i = 0; i < matchedID .length; i++) {
    if(matchedID[i].classList.contains('active')) {
      matchedID[i].classList.remove('active');
    } else {
      matchedID[i].classList.add('active');
    }
  }
  
  //e.preventDefault();
  //shareDialog.classList.remove('is-open');
  //console.log(matchedID);
}
function portfolioPage(e) {
  var portfolioForm = document.getElementById('portfolioForm');
  if(e.currentTarget.id == 'pFormNext') {
    if(portfolioForm.classList.contains('currentPage1')) {
      portfolioForm.classList.remove('currentPage1');
      portfolioForm.classList.add('currentPage2');
    } else if(portfolioForm.classList.contains('currentPage2')) {
      portfolioForm.classList.remove('currentPage2');
      portfolioForm.classList.add('currentPage3');
    }
  } else {
    if(portfolioForm.classList.contains('currentPage2')) {
      portfolioForm.classList.remove('currentPage2');
      portfolioForm.classList.add('currentPage1');
    } else if(portfolioForm.classList.contains('currentPage3')) {
      portfolioForm.classList.remove('currentPage3');
      portfolioForm.classList.add('currentPage2');
    }
  }
}
function portfolioFrameClose() {
  var portfolioIframe = document.getElementById('portfolioIframe');
  var portfolioIframeClose = document.getElementById('portfolioIframeClose');
  var portfolioNameOverlay = document.getElementById('portfolioNameOverlay');
  portfolioIframe.src = '';
  portfolioIframe.setAttribute("style", "display:none;");
  portfolioIframeClose.setAttribute("style", "display:none;");
  portfolioNameOverlay.setAttribute("style", "display:none;");
  portfolioNameOverlay.innerHTML = '';
}
function withProdData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={graphql`
        {
          wp {
            pages {
              edges {
                node {
                  slug
                  childPages(where: {orderby: {field: TITLE, order: ASC}}) {
                    edges {
                      node {
                        title
                        pageId
                        slug
                        childPages(where: {orderby: {field: TITLE, order: ASC}}) {
                          edges {
                            node {
                              pageId
                              slug
                              childPages(where: {orderby: {field: TITLE, order: ASC}}, first: 20) {
                                edges {
                                  node {
                                    pageId
                                    slug
                                    Product_Page_Fields {
                                      productName
                                    }

                                  }
                                }
                              }
                              title
                              Brand_Page_Fields {
                                brandName
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  pageId
                  title
                }
              }
            }
          }
        
      }
    `}
    render={data => <WrappedComponent {...props} prodData
    
    ={data} />}
    />
  );
}

const ProductListing = withProdData(props => (
  <ul>
  {/*props.prodData.allWordpressPage.edges.map(brand => (
    <React.Fragment>
      {<li><span dangerouslySetInnerHTML={{ __html: brand.node.acf.product_name}}/> &nbsp;|&nbsp; {brand.node.path}</li>}
      {/*<div className="hide">{options.push({"label": brand.node.acf.product_name.toString().replace('<span class="reg"></span>','®'),"value": brand.node.wordpress_id})}</div>}
    </React.Fragment>
  ))*/}
  <div className="">{formArray.categories.length=0}</div>
  {props.prodData.wp.pages.edges.map((top) =>(
    <React.Fragment>
      {top.node.childPages.edges.map((category,iC) =>(
        <React.Fragment>
          <div className="">{formArray.categories.push({"title": category.node.title,"pageID": category.node.pageId,"brands": [] })}</div>
          {category.node.childPages.edges.map((brand, iB) =>(
            <React.Fragment>
              <div className="brandName">{formArray.categories[iC].brands.push({"title": brand.node.Brand_Page_Fields.brandName.toString().replace('<span class="reg"></span>','®'),"page_id": brand.node.pageId, "slug": brand.node.slug, "products": [] })}</div>
              {brand.node.pageId}
              {brand.node.Brand_Page_Fields.brandName.toString().replace('<span class="reg"></span>','®')}
              {brand.node.childPages.edges.map((product, iP) =>(
                <React.Fragment>
                  <div className="">{formArray.categories[iC].brands[iB].products.push({"title":brand.node.Brand_Page_Fields.brandName.toString().replace('<span class="reg"></span>','®') , "label": product.node.Product_Page_Fields.productName.toString().replace('<span class="reg"></span>','®'),"value": '/'+top.node.slug+'/'+category.node.slug+'/'+brand.node.slug+'/products/'+product.node.slug})}</div>
                  {product.node.Product_Page_Fields.productName}<br/>
                  <div className="">{options.push({"label": product.node.Product_Page_Fields.productName.toString().replace('<span class="reg"></span>','®'),"value": product.node.pageId})}</div>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  ))}
  </ul>
));

class Collections extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      portfolioName: '',
      loading: false,
      collections: [],
      //limit: 21,
      value: 'select',
      inputValue: '',
      portfolios: '',
      authUser: '',
      portCount: '',
      allOptions: []
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.onListenForCollections();
    }
  };



  componentDidMount() {
    this.firebaseInit();
    const node = ReactDOM.findDOMNode(this);
    //this.childPort = node.querySelectorAll('.portfolioItem').length; 
    
  }

  componentDidUpdate = () => {
    this.firebaseInit();   
    const node = ReactDOM.findDOMNode(this);
    
    //console.log('all ports:', node.querySelectorAll('.portfolioItem').length);
    //console.log('archived ports:', node.querySelectorAll('.portfolioItem.archivedP').length);
    //console.log('active ports:', node.querySelectorAll('.portfolioItem.activeP').length);
    //this.childPort = node.querySelectorAll('.portfolioItem').length; 
    //this.setState({childPort: this.state.collections.length});
    this.childPort = this.state.collections.length;
    //this.childPortActive = node.querySelectorAll('.portfolioItem.activeP').length; 
    //this.childPortArchive = node.querySelectorAll('.portfolioItem.archivedP').length;    
  }


  onListenForCollections = () => {
    this.setState({ loading: true });

    //this.props.firebase
    this.unsubscribe = this.props.firebase
      .collections()
      //.orderByChild('createdAt')
      .orderBy('createdAt', 'desc')
      //.limitToLast(this.state.limit)
      //.limit(this.state.limit)
      //.on('value', snapshot => {
      .onSnapshot(snapshot => {
        //const collectionObject = snapshot.val();
        if (snapshot.size) {
          let collections = [];
          snapshot.forEach(doc =>
            collections.push({ ...doc.data(), uid: doc.id }),
          );

        //if (collectionObject) {
          //const PortfoliosList = Object.keys(collectionObject).map(key => ({
            //...collectionObject[key],
            //uid: key,
          //}));

          this.setState({
            //collections: PortfoliosList,
            collections: collections.reverse(),
            loading: false,
          });
        } else {
          this.setState({ collections: null, loading: false });
        }
      });
  }; 

  componentWillUnmount() {
    //this.props.firebase.collections().off();
    this.unsubscribe();

    
  }

  onChangeText = event => {
    this.setState({ text: event.target.value });
  };
  onChangePortName = event => {
    this.setState({ portfolioName: event.target.value });
  };
  
  onCreateCollection = (event, authUser) => {
    //this.props.firebase.collections().push({
    var filtered = this.state.allOptions.filter(function (el) {
      return el != null;
    });
    this.props.firebase.collections().add({
      text: this.state.text,
      portfolioName: this.state.portfolioName,
      portBrands: this.state.allOptions,
      archived: false,
      userId: authUser.uid,
      //userName: authUser.username,
      //createdAt: this.props.firebase.serverValue.TIMESTAMP,
      createdAt: this.props.firebase.fieldValue.serverTimestamp(),
      indexId: this.state.collections.filter(collections => collections.userId === authUser.uid ).length
    });

    this.setState({ text: '', portfolioName: '', portBrands: [], allOptions: [] });

    event.preventDefault();
    var pForm = document.getElementById("portfolioForm");
    var createForm = document.getElementById("createPortfolio");

    
    createForm.reset();

    const selects = pForm.getElementsByClassName('product-selector');
  
    for (var i = 0; i < selects .length; i++) {
        selects[i].value = "";
    }
    setTimeout(function() {
      window.location.reload();
    }, 1000);
    
  };

  onEditCollection = (collection, text, portfolioName, portBrands) => {
    const { uid, ...collectionSnapshot } = collection;

    //this.props.firebase.collection(collection.uid).set({
    this.props.firebase.collection(collection.uid).update({
      ...collectionSnapshot,
      text,
      portfolioName,
      portBrands,
      //editedAt: this.props.firebase.serverValue.TIMESTAMP,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  onChangeArchiveTrue = (uid, collections, userId, indexId) => {
    this.props.firebase.collection(uid).update({
      archived: true
    });
    collections.filter(collection => {return collection.userId === userId, collection.indexId > indexId;}).map((item, index) => (
      this.props.firebase.collection(item.uid).update({
        indexId: item.indexId - 1
      })
    ));
  };

  onChangeArchiveFalse = uid => {
    this.props.firebase.collection(uid).update({
      archived: false,
      indexId: document.querySelectorAll('.activePI').length
    });
  };
  /*
  onChangeArchiveCollection = uid => {
    //this.props.firebase.collection(uid).remove();
    this.props.firebase.collection(uid).update({
      archived
    });
  };
  */

 onChangeOrder = (items) => {
  console.log(items);
  items.map((item, index) => (
    this.props.firebase.collection(item.value.uid).update({
      indexId: index,
    })
  ));
  this.setState(({collections}) => ({
    collections: collections
  }));
  console.log('finished')
      /*this.props.firebase.collection(uid).update({
    indexId: newIndex
  });*/
};

  onRemoveCollection = uid => {
    //this.props.firebase.collection(uid).remove();
    this.props.firebase.collection(uid).delete();
    this.setState({childPort: this.state.collections.length});
  };

  onNextPage = () => {
    this.setState(
      //state => ({ limit: state.limit + 20 }),
      this.onListenForCollections,
    );
  };
  

  state = {
    selectedOption: [],
  };

  /*
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };
  */

  onInputChange = (option, { action }) => {
    console.log(option, action);
    if (action === "input-change") {
      const optionLength = option.length;
      const inputValue = this.state.inputValue;
      const inputValueLength = inputValue.length;

      const newInputValue =
        optionLength < inputValueLength
          ? option
          : this.state.inputValue + option[option.length - 1];
      this.setState({
        inputValue: newInputValue
      });
    }
  };

  onChange = option => {
    this.setState({
      value: option
    });
  };


  
  handleInputChange = (value, e) => {
    if (e.action === "input-change") {
      this.setState({ value });

    }
  };

  handleChange = (selected, index) => {
    const removed = index.removedValue; 
    const clearedBrand = index.action;
    const clearedBrandName = index.name;
    console.log(index);
    if(clearedBrand == 'clear') {
      //console.log(clearedBrandName);
      //const newOptions = this.state.allOptions;
      var filtered = this.state.allOptions.filter(function (el) {
        return el != null;
      });
      //console.log(filtered);
      const result = filtered.filter(item => item.title != clearedBrandName);
      console.log(result);
      this.setState(
        { selectedOption: selected, allOptions: result },
        //() => console.log(`Option selected:`, this.state.selectedOption),
        //() => console.log(`Option selected:`, this.state.allOptions)
      );
    } else if(index.removedValue == null) {
      this.setState(
        { selectedOption: selected, allOptions: Array.from(new Set(allOptions.concat(this.state.allOptions, selected))) },
        //() => console.log(`Option selected:`, this.state.selectedOption),
        () => console.log(`Option selected:`, this.state.allOptions)
      );
    } else {
      const newOptions = this.state.allOptions;
      const removeIndex = newOptions.findIndex(x => x === removed);
      const filteredItems = newOptions.slice(0, removeIndex).concat(newOptions.slice(removeIndex + 1, newOptions.length))
      console.log(filteredItems);
      this.setState(
        { selectedOption: selected, allOptions: filteredItems},
      );
    }
  };

  

  render() {
    
  const { text, portfolioName, collections, loading, selectedOption, allOptions, childPort, portCount, tick } = this.state;

    return (
      <AuthUserContext.Consumer>
        
        {authUser => (
          <section id="portfolio-content" className="flexContainer active secondaryTab" css={css`padding-top: calc(3.85 * 18px) !important;`}>
            <div id="portfolioHeader" className="fCol12 pActive">
              <div className="flexContainer fCol12">
                  <div className="portfolioInfo">
                      <h1 id="activePortTitle">MY PORTFOLIOS</h1>
                      <h1 id="archivedPortTitle">MY HIDDEN PORTFOLIOS</h1>
                      <p id="activePortContent">
                          {this.state.collections.filter(collections => collections.userId === authUser.uid).length != 20 &&
                           <span> Categorize your top-viewed products into portfolios according to account, product type or other grouping for easy access. You may add up to 20 portfolios.</span>
                          }
                          {this.state.collections.filter(collections => collections.userId === authUser.uid).length === 20 &&
                           <span>Please delete a portfolio before adding more..</span>
                          }
                      </p>
                      <p id="archivedPortContent">
                          These portfolios have been archived/hidden from view, but can be reactivated by clicking on the eyeball.
                      </p>
                  </div>
                  <div className="portfolioHeaderButtons flexContainer">
                      {this.state.collections.filter(collections => collections.userId === authUser.uid).length != 20 &&
                        <button className="createNewPortfolio" onClick={portfolioFormOpen}><span className="icon-CreateNewPortfolio-rev1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span>Create a New Portfolio</button>  
                      }
                      <button onClick={revealArchived} className="archiveButton"><span class="icon-FolderEye"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span id="archiveCount" className="archiveCount">{this.state.collections.filter(collections => (collections.userId === authUser.uid && collections.archived === true)).length}</span></span></button>
                      <button onClick={revealArchived} className="activePortfolioButton archived"><span class="icon-CreateNewPortfolio"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span id="activeCount" className="activeCount">{this.state.collections.filter(collections => (collections.userId === authUser.uid && collections.archived === false)).length}</span></span>Back to Visible Portfolios</button>
                  </div>
              </div>
            </div>  

          <div id="portfolioListContainer" className="fCol12 activeP">
            {loading && <div>Loading ...</div>}
            {collections && (
              <PortfoliosList
                authUser={authUser}
                collections={collections}
                onEditCollection={this.onEditCollection}
                onChangeArchiveTrue={this.onChangeArchiveTrue}
                onChangeArchiveFalse={this.onChangeArchiveFalse}
                onRemoveCollection={this.onRemoveCollection}
                onChangeOrder={this.onChangeOrder}
                tick={this.tick}
              />
            )}

            {!collections && <div>There are no portfolios ...</div>}
            <div id="portfolioForm" className={'portfolioForm form-group currentPage1'}>
              <button className="close" onClick={portfolioFormClose}/>
              <form
                id="createPortfolio"
                onSubmit={event =>
                  this.onCreateCollection(event, authUser)
                }
              >
                
                <div id="pFormStep1" className="pFormStep active">
                  <h3>Create A New Portfolio</h3>
                  <input
                    type="text"
                    value={portfolioName}
                    placeholder="PORTFOLIO NAME"
                    onChange={this.onChangePortName}
                  />
                  <input
                    type="text"
                    value={text}
                    onChange={this.onChangeText}
                    placeholder="PORTFOLIO DESCRIPTION"
                  />
                </div>
                <div id="pFormStep2" className="pFormStep">
                  <p className="portfolioHeader">
                  Select product groups from which you want<br />to include products for<br/><span>{portfolioName}</span>
                  </p>
                  {console.log(formArray)}
                  {formArray.categories.map((category, index) => (
                    <React.Fragment>
                      {category.brands.length > 0 &&
                        <div class="portfolioCheckbox">
                          <input
                            className="form-control"
                            id={category.pageID}
                            //id={'pCategory'+index}
                            //name={category.pageID}
                            name={'pCategory'+index}
                            type="checkbox"
                            //checked={props.pCategory}
                            onChange={toggleMatching}
                          />
                          <label for={category.pageID}>{category.title}</label>
                        </div>
                      }
                      {category.brands.length == 0 &&
                        <div class="portfolioCheckbox disabled">
                          {category.title}
                        </div>
                      }
                    </React.Fragment>
                  ))}
                </div>
                <div id="pFormStep3" className="pFormStep">
                  <p className="portfolioHeader">
                    Select product to add to<br/><span>{portfolioName}</span>
                  </p>
                  <div id="multiSelectContainer" class="flexContainer">
                    {formArray.categories.map(category => (
                      <React.Fragment>
                        {category.brands.map((brand,index) => (
                          brand.products.length > 0 &&
                          <React.Fragment>
                            {console.log(brand.products)}
                            <div className={'productMultiSelect select'+category.pageID}>
                            <h3 dangerouslySetInnerHTML={{ __html: brand.title}}/>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              //isSearchable={false}
                              label={brand.title}
                              className={'product-selector '+brand.slug}
                              classNamePrefix='productS'
                              placeholder=""
                              id={'select'+brand.page_id}
                              name={brand.title}
                              //id={brand.products.value}
                              value={this.inputValue}
                              allOptions={this.state.allOptions}
                              inputValue={this.state.inputValue}
                              //value={this.state.value}
                              onInputChange={this.onInputChange}
                              //onChange={this.onChange.bind(this)}
                              onChange={this.handleChange.bind(this)}
                              //onChange={this.handleChange.bind(selectedOption, this.selectedOption)}
                              //onChange={(selectedOption) => this.handleChange(selectedOption, this.selectedOption)}
                              //noSelection={this.state.noSelection}
                              options={brand.products}
                              //options={product}
                            />
                            </div>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                    <Select
                      isMulti
                      //closeMenuOnSelect={false}
                      label='all'
                      className='all'
                      classNamePrefix='all'
                      placeholder="Select Product to add to Portfolio"
                      id='allSelected'
                      name='select-all'
                      //id={brand.products.value}
                      value={allOptions}
                      inputValue={this.state.inputValue}
                      //value={this.state.value}
                      onInputChange={this.handleChange.bind(this)}
                      //onChange={this.onChange.bind(this)}
                      onChange={this.handleChange.bind(this)}
                      //onChange={this.handleChange.bind(selectedOption, this.selectedOption)}
                      //onChange={(selectedOption) => this.handleChange(selectedOption, this.selectedOption)}
                      noSelection={this.state.noSelection}
                      options={allOptions}
                      //options={product}
                    />
                  </div>
                </div>
                <div id="pFormButtons" class="flexContainer">
                
                <div id="pFormPrev" onClick={portfolioPage}>Back</div>
                <button id="portfolioAddSubmit" type="submit">Save</button>
                {portfolioName != '' &&
                  <div id="pFormNext" onClick={portfolioPage}>Continue</div>
                }
                {portfolioName == '' &&
                  <div id="pFormNext" className="disabled">Continue</div>
                }
                </div>
              </form>
              
            </div>
            <div id="portfolioNameOverlay"></div>
            <iframe id="portfolioIframe" src=""/>
            
            <button id="portfolioIframeClose" onClick={portfolioFrameClose}/>
            {/*<h4>Products feeding from Portfolios.js</h4>
            {options.length=0}*/}
            <div className="hide"><ProductListing /></div>
            {/*
            <StaticQuery
              query={graphql`
                {allWordpressPage(filter: {acf: {type_of_page: {eq: "product"}}}) {
                  edges {
                    node {
                      id
                      acf {
                        product_name
                        brand_name
                      }
                      path
                      wordpress_id
                    }
                  }
                }
              }
            `}
            render={data => (
                            
              <ul>
                {data.allWordpressPage.edges.map(brand => (
                  <React.Fragment>
                    <li><span dangerouslySetInnerHTML={{ __html: brand.node.acf.product_name}}/> &nbsp;|&nbsp; {brand.node.path}</li> 
                  </React.Fragment>
                ))}
              </ul>
            
            )}
            />
            */}

            


          </div>
          </section>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Collections);